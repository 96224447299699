<template>
  <div>
    <c-search-box @enter="getApprTypeList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="결재유형 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getApprTypeList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addApprType" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveApprType"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteApprType"
                  @btnCallback="deleteCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :disabled="true"
                  label="결재유형코드"
                  name="approvalTypeCd"
                  v-model="data.approvalTypeCd">
                </c-text>
              </div>
              <div class="col-9">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="결재업무명"
                  name="approvalTypeName"
                  v-model="data.approvalTypeName">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="결재화면 page경로"
                  name="approvalUrl"
                  v-model="data.approvalUrl">
                </c-text>
              </div>
              <div class="col-9">
                <c-text
                  :editable="editable"
                  :required="true"
                  label="결재화면 파라메터"
                  name="approvalParam"
                  v-model="data.approvalParam">
                </c-text>
              </div>
              <div class="col-3">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  label="결재메일내용"
                  name="approvalContent"
                  v-model="data.approvalContent"
                  :rows="3"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'appr-manage',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'approvalTypeCd',
            field: 'approvalTypeCd',
            label: '결재유형코드',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'approvalTypeName',
            field: 'approvalTypeName',
            label: '결재업무명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        approvalTypeCd: '',  // 결재유형코드
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalParam: '',  // 결재파라메터
        approvalContent: '',  // 결재메일내용
        useFlag: 'Y',  // 사용여부
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.appr.type.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sys.appr.type.list.url;
      this.detailUrl = selectConfig.sys.appr.type.get.url;
      this.insertUrl = transactionConfig.sys.appr.type.insert.url;
      this.updateUrl = transactionConfig.sys.appr.type.update.url;
      this.deleteUrl = transactionConfig.sys.appr.type.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getApprTypeList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.approvalTypeCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addApprType() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.data = {
        approvalTypeCd: '',  // 결재유형코드
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalParam: '',  // 결재파라메터
        approvalContent: '',  // 결재메일내용
        useFlag: 'Y',  // 사용여부
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.data = {
        approvalTypeCd: '',  // 결재유형코드
        approvalTypeName: '',  // 결재업무명
        approvalUrl: '',  // 결재화면 page경로
        approvalParam: '',  // 결재파라메터
        approvalContent: '',  // 결재메일내용
        useFlag: 'Y',  // 사용여부
      };
    },
    saveApprType() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteApprType() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.approvalTypeCd);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            console.log(_result);
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ approvalTypeCd: result.data.approvalTypeCd })
      } else {
        this.getApprTypeList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getApprTypeList();
    },
  }
};
</script>
